import { useRef, useEffect } from 'react'

function useCheckoutAddressStoreIdUpdate(callback, checkoutAddress) {
  const prevCheckoutAddressRef = useRef()

  useEffect(() => {
    const prevCheckoutAddress = prevCheckoutAddressRef.current
    prevCheckoutAddressRef.current = checkoutAddress

    if (
      prevCheckoutAddress &&
      prevCheckoutAddress.storeId !== checkoutAddress.storeId
    ) {
      callback(prevCheckoutAddress)
    }
  }, [checkoutAddress, callback])
}

function useTrackPromoImpression({
  elementRef,
  createObserverInfo,
  checkoutAddress,
  queue,
  impressionTrackingData: bannerData,
  collection,
  isCategory = true,
}) {
  const prevCheckoutAddressRef = useRef()

  const createObserver = (params = {}) => {
    if (createObserverInfo) {
      const options = {
        elementRef: elementRef.current,
        bannerTrackObj: bannerData,
        callAction: 'onLoad',
      }
      if (params?.action === 'afterUpdate') {
        options.callAction = 'afterUpdate'
      }
      createObserverInfo(options)
    }
  }

  useEffect(() => {
    if (
      isCategory &&
      collection &&
      collection.category &&
      Object.keys(collection.category).length
    ) {
      queue.pushTask(() => {
        createObserver()
      })
    } else if (!isCategory && collection) {
      queue.pushTask(() => {
        createObserver()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collection])

  useEffect(() => {
    const prevCheckoutAddress = prevCheckoutAddressRef.current
    prevCheckoutAddressRef.current = checkoutAddress

    if (
      prevCheckoutAddress &&
      prevCheckoutAddress.storeId !== checkoutAddress.storeId
    ) {
      if (
        isCategory &&
        collection &&
        collection.category &&
        Object.keys(collection.category).length
      ) {
        createObserver({ action: 'afterUpdate' })
      } else if (!isCategory && collection) {
        createObserver({ action: 'afterUpdate' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAddress])
}

export { useCheckoutAddressStoreIdUpdate, useTrackPromoImpression }
