import {
  getNCheckFn as getNCheck,
  GTM_ENV_VAR as GEV,
  SPLIT_EVENT,
} from '../../lib/eventTrackerFunction'
import {
  createNewObserver,
  getImpressionData,
  impressionTypes,
  trackingTypes,
  triggerImpressionsTracking,
} from '../../lib/impressionsTracker'
import {
  trackProductImpressions,
  trackPromoImpressions,
} from '../../lib/trackClicksAndImpressions'
import { useState, useEffect, useRef } from 'react'

import {
  getProductImpressionsTrackingData,
  getSplitProductTrackingData,
} from '../../lib/getProductImpressionsTrackingData'

export const usePromoImpressionTracker = ({
  track,
  trackSplit,
  checkoutAddress,
  meta,
  impressionType = impressionTypes.OTHER_BANNER_IMPRESSION,
}) => {
  const [impressionsTracked, setImpressionsTracked] = useState(false)
  const mounted = useRef()
  const trackImpressions = (
    params = {
      action: '',
      prevStoreId: '',
      prevDBPStoreId: '',
    }
  ) => {
    const storeIdToTrack =
      params.prevStoreId ||
      getNCheck(checkoutAddress, 'clientId', '').toString()
    const dbpStoreIdToTrack =
      params.prevDBPStoreId ||
      getNCheck(checkoutAddress, 'storeId', '').toString()

    trackPromoImpressions({
      track,
      trackSplit,
      storeIdToTrack,
      dbpStoreIdToTrack,
      banners: [],
      currentType: impressionType,
      params: {
        ...params,
        ...meta,
      },
    })

    if (params.action === 'trackOnUpdate' && !impressionsTracked) {
      setImpressionsTracked(true)
    }
  }

  const createObserver = ({ elementRef, bannerTrackObj, callAction }) => {
    if (
      callAction === 'onLoad' ||
      (callAction === 'afterUpdate' && impressionsTracked)
    ) {
      createNewObserver({
        productRef: elementRef,
        trackType: trackingTypes.TRACK_ONCE,
        productTrackingData: bannerTrackObj,
        splitTrackingData: {
          ...bannerTrackObj,
          event_type: SPLIT_EVENT,
          [GEV.EVENT]: 'splitPromoImpression',
        },
        options: {
          root: null,
          rootMargin: '0px',
          threshold: 0.01,
        },
        impressionType: impressionType,
        splitImpressionType: impressionTypes.SPLIT_RECIPE_IMPRESSION,
      })

      if (impressionsTracked) {
        setImpressionsTracked(false)
      }
    }
  }

  useEffect(() => {
    triggerImpressionsTracking.subscribe(impressionType, data => {
      if (data === impressionType) {
        trackImpressions()
      }
    })
    return () => {
      triggerImpressionsTracking.unsubscribe(impressionType)
      trackImpressions({ forceTrack: true, action: 'abandon' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      trackImpressions({
        action: 'trackOnUpdate',
        prevStoreId: getNCheck(checkoutAddress, 'clientId', '').toString(),
        prevDBPStoreId: getNCheck(checkoutAddress, 'storeId', '').toString(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAddress.storeId])

  return { createObserver, trackImpressions }
}

export const useTrackPromoImpression = ({
  elementRef,
  createObserverInfo,
  checkoutAddress,
  itemId,
  queue,
  impressionTrackingData: bannerData,
}) => {
  const mounted = useRef()

  const createObserver = (params = {}) => {
    if (createObserverInfo) {
      const options = {
        elementRef: elementRef.current,
        bannerTrackObj: bannerData,
        callAction: 'onLoad',
      }
      if (params?.action === 'afterUpdate') {
        options.callAction = 'afterUpdate'
      }
      createObserverInfo(options)
    }
  }

  useEffect(() => {
    if (elementRef && itemId) {
      queue.pushTask(() => {
        createObserver()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (elementRef && itemId) {
        createObserver({ action: 'afterUpdate' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAddress, elementRef])
}

export const useProductImpressionTracker = ({
  track,
  trackSplit,
  remoteConfig,
  queue,
  checkoutAddress,
  loc,
  recipeTitle,
}) => {
  const [impressionsTracked, setImpressionsTracked] = useState(false)
  const [noImpressions, setNoImpressions] = useState(false)
  const mounted = useRef()

  const trackImpressions = (
    params = {
      forceTrack: false,
      eventType: undefined,
      thresholdReached: false,
      prevStoreId: '',
    }
  ) => {
    const products = getImpressionData(impressionTypes.PRODUCT_IMPRESSION)
    if (!products.length) {
      return
    }

    const storeIdToTrack =
      params.prevStoreId ||
      getNCheck(checkoutAddress, 'clientId', '').toString()
    trackProductImpressions({
      params,
      currentType: impressionTypes.PRODUCT_IMPRESSION,
      storeIdToTrack,
      searchTermToTrack: '',
      track,
      trackSplit,
      products,
      remoteConfig,
      eventProps: {
        [GEV.ASSET_TYPE]: 'Lifestyle',
        [GEV.EVENT_LABEL]: `loc=${loc}`,
        [GEV.EVENT_ACTION]: 'productImpression',
        [GEV.RECIPE_TITLE]: recipeTitle,
      },
    })
  }

  const createObserver = ({
    productRef,
    productTrackObj,
    callAction,
    splitTrackObj,
  }) => {
    if (
      callAction === 'onLoad' ||
      (callAction === 'afterUpdate' && (impressionsTracked || noImpressions))
    ) {
      createNewObserver({
        productRef,
        trackType: trackingTypes.TRACK_ONCE, // signifies tracked once per page load
        productTrackingData: productTrackObj,
        splitTrackingData: splitTrackObj,
        options: {
          root: null,
          rootMargin: '0px',
          threshold: 0.01, // signifies that min 1% of the product card is visible
        },
        impressionType: impressionTypes.PRODUCT_IMPRESSION,
        splitImpressionType: impressionTypes.SPLIT_PRODUCT_IMPRESSION,
      })
    }

    if (callAction === 'afterUpdate') {
      setImpressionsTracked(false)
      setNoImpressions(false)
    }
  }

  useEffect(() => {
    triggerImpressionsTracking.subscribe(
      impressionTypes.PRODUCT_IMPRESSION,
      data => {
        if (data === impressionTypes.PRODUCT_IMPRESSION) {
          queue.pushTask(() => {
            trackImpressions({ thresholdReached: true })
          })
        }
      }
    )
    return () => {
      triggerImpressionsTracking.unsubscribe(impressionTypes.PRODUCT_IMPRESSION)
      trackImpressions({ forceTrack: true, action: 'abandon' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      trackImpressions({
        action: 'trackOnUpdate',
        prevStoreId: getNCheck(checkoutAddress, 'clientId', '').toString(),
        dbpStoreIdToTrack: getNCheck(checkoutAddress, 'storeId', '').toString(),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAddress.storeId])

  return {
    createObserver,
    trackImpressions,
  }
}

export const useTrackProductImpression = ({
  elementRef,
  createObserverInfo,
  queue,
  itemId,
  impressionTrackingData,
  checkoutAddress,
}) => {
  const mounted = useRef()

  const createObserver = (params = {}) => {
    if (createObserverInfo) {
      const productData = getProductImpressionsTrackingData({
        productObj: impressionTrackingData,
      })
      const options = {
        productRef: elementRef.current,
        productTrackObj: productData,
        splitTrackObj: getSplitProductTrackingData({
          eventType: 'splitProductImpression',
          productObj: impressionTrackingData,
        }),
        callAction: 'onLoad',
      }
      if (params?.action === 'afterUpdate') {
        options.callAction = 'afterUpdate'
      }
      createObserverInfo(options)
    }
  }

  useEffect(() => {
    if (elementRef && itemId) {
      queue.pushTask(() => {
        createObserver()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef])

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true
    } else {
      if (elementRef && itemId) {
        createObserver({ action: 'afterUpdate' })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutAddress, elementRef])
}
